import { TButton } from '@/Components/TButton';
import styled from 'styled-components';

interface ContainerProps {
    backgroundUrl: string;
}

export const HiddenInput = styled.div`
    position: fixed;
    top: -100px;
`

export const AutoAttendantContainer = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    align-items: center;

    background-image: url(${(props) => props.backgroundUrl});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    width: 100vw;
    height: 100vh;
`;

export const Title = styled.h1`
    color: ${({ theme }) => theme.colors.white};
    font-weight: bold;
    text-transform: uppercase;
    font-size: ${({ theme }) => theme.fontSize['3xl']};

    margin-top: 75px;
`;

export const AutoAttendantContent = styled.div`
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 150px;
`;

export const AutoAttendantButton = styled(TButton)`
    width: 350px;
    height: 150px;
    font-size: ${({ theme }) => theme.fontSize['3xl']};
    font-weight: bold;
    background: rgb(0 0 0 / 50%);
    border-radius: 30px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
`;

export const CPanelButton = styled.button`
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;

    position: fixed;
    top: 0;
    right: 0;

    margin: 10px;
`;

export const TranslateButton = styled.button`
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;

    position: fixed;
    top: 0;
    right: 50px;

    margin: 10px;
`;
