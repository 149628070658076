import { InputHTMLAttributes, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';

import {
    CheckInContainer,
    CheckInForm,
    CheckInTitle,
    CheckoutButton,
    ErrorMessage,
    FormLabel,
    KeyboardContainer,
    PaymentContainer,
    QRCodeContainer,
    SearchByContainer,
    WelcomeMessage,
} from './styled';
import ModalLoading from '@/Components/ModalLoading';

import * as yup from 'yup';
import ApiService from '@/Services/api/ApiService';
import { useToken } from '@/Components/UseToken';
import { EventResponse, IEnglishParticipantSearchBy, IParticipantSearchBy } from '@/Services/api/models/eventModel';
import { GetParticipantResponse } from '@/Services/api/models/participantsModel';
import ModalNotificacaoError from '@/Components/ModalNotificacaoError';
import { useIdleTimer } from 'react-idle-timer';
import ModalConfirmIdentity from '@/Components/ModalConfirmIdentity';
import { handlePrintTicket } from '@/util/print';
import { FiArrowLeft } from 'react-icons/fi';
import { BackArrow } from '@/Components/ArrowBack';
import { useAutoAttendantNavigate } from '@/Components/CustomNavigate/autoattendant';
import TKeyboard from '@/Components/Keyboard';
import { UsersResponse } from '@/Services/api/models/usersModel';
import RsvpService from '@/Services/rsvp/RsvpService';
import QRCode from 'react-qr-code';
import NextInput from '@/Components/NextInput';



interface CheckInFormValues {
    document: string;
}

export function AutoAttendantCheckout() {
    const location = useLocation();
    const { token } = useToken();
    const [startTimestamp, setStartTimestamp] = useState<Date>()
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [participantFound, setParticipantFound] = useState<GetParticipantResponse>();
    const [backgroundImage, setBackgroundImage] = useState('');
    const [eventInfos, setEventInfos] = useState<EventResponse>();
    const [paymentQRCode, setPaymentQRCode] = useState<string>()
    const [orderId, setOrderId] = useState<number>()
    const [success, setSuccess] = useState<boolean>(false)

    const [showModal, setShowModal] = useState<boolean>(false);
    const [modalMessage, setModalMessage] = useState<string>('');

    const [errorOnPrint, setErrorOnPrint] = useState(false);

    const [showIdentityModal, setShowIdentityModal] = useState(false);

    const [input, setInput] = useState<string>('')

    const { language } = useParams()
    const [english, setEnglish] = useState<boolean>(false)

    const navigate = useAutoAttendantNavigate(location.state?.backgroundImage ?? backgroundImage);

    const userInfoString = localStorage.getItem('userInfo');
    const userInfo: UsersResponse = JSON.parse(userInfoString) ?? {}

    useIdleTimer({
        timeout: 60000,
        onIdle: () => {
            if (!errorOnPrint) navigate('/autoattendant/');
        },
    });

    const onConfirmCheckout = async () => {
        try {
            setIsLoading(true);
            if (!participantFound) {
                throw new Error(english? 'No participant found' : 'Não encontramos nenhum participante');
            }

            if (!eventInfos) {
                throw new Error(english? 'No events linked to this participant' : 'Nenhum evento vinculado a esse participante');
            }

            if (participantFound) {
                if(participantFound.categoryId !== 1){
                    throw new Error(english? 'Please look for the reception to check in' : 'Procure a recepção para fazer o seu check-in')
                }
            }

            const d = new Date();
            const endTimestamp =  d.getTime() - startTimestamp.getTime()
            d.setHours(d.getHours() - 3);
            if(participantFound.documentNumber !== '00000000000') {
                await ApiService.checkInParticipant(participantFound.id, d.toISOString(), endTimestamp)
            }
            if(!eventInfos?.surveyControl) {
                if(eventInfos.labelConfiguration !== 'No label') { await handlePrintTicket(eventInfos, participantFound) };
                setShowModal(true);
                setSuccess(true)
            }
            else {
                const questions = await ApiService.getQuestionsByEventId(eventInfos?.id)
                const checkInQuestions = questions.filter((question) => question.checkinQuestion === true)
                if(checkInQuestions?.length > 0) {
                    navigate(`/questoes/${eventInfos.id}/${participantFound.id}`)   
                }
                else {
                    if(eventInfos.labelConfiguration !== 'No label') { await handlePrintTicket(eventInfos, participantFound) };
                    setShowModal(true);
                    setSuccess(true);
                }
            }
        } catch (e: any) {
            setShowModal(true);
            if((e?.response?.data?.message)?.toString()?.includes('CheckIn já realizado para o participante')) {
                setModalMessage(english? 'Participant has already checked in' : 'CheckIn já realizado')
            }
            else {
                setModalMessage(e.message);
            }
            setErrorOnPrint(true);
        } finally {
            setIsLoading(false)
            if(!eventInfos?.surveyControl) {
                setTimeout(() => {
                    navigate('/autoattendant/');
                }, 4000);
            }
        }
    };

    const getEventInfo = async () => {
        try {
            setIsLoading(true);
            const event = await ApiService.getEventById(userInfo?.events?.[0] ?? 0);
            setEventInfos(event);
        } catch (e: any) {
            toast.error(e.message);
        } finally {
            setIsLoading(false);
        }
    };

    const loadEventImage = async () => {
        try {
            setIsLoading(true);
            const backgroundImage = await ApiService.getBackgroundImage(userInfo?.events?.[0] ?? 0);
            setBackgroundImage(backgroundImage);
        } catch (e: any) {
            toast.error(e.message);
        } finally {
            setIsLoading(false);
        }
    };

    const userFilterDicionary = (
        participants: GetParticipantResponse[],
        searchBy: keyof typeof IParticipantSearchBy,
        valueToSearch: string,
    ): GetParticipantResponse | -1 => {
        let participantFound: GetParticipantResponse;

        const formatedValue = valueToSearch.toLocaleLowerCase().trim();
        
        const participantsFound = participants?.filter((p) => p?.checkInQrCode === valueToSearch)
        if(participantsFound && participantsFound.length === 1) {
            participantFound = participantsFound[0]
            return participantFound
        } else {
            switch (searchBy) {
                case 'NAME':
                    const nameFound = participants.filter((participant) => {
                        const name = `${participant.name.toLocaleLowerCase()}${
                            !participant.lastName ? '' : participant.lastName
                        }`.replace(/ /g, '').toLocaleLowerCase();
                        return name.startsWith(formatedValue.replace(/ /g, ''));
                    })[0];
                    if (nameFound) {
                        return nameFound;
                    } else {
                        return -1;
                    }
                    break;
                case 'EMAIL':
                    participantFound = participants.filter(
                        (participant) => participant.email.toLocaleLowerCase() === formatedValue,
                    )[0];
                    break;
                case 'DOCUMENT_NUMBER':
                    participantFound = participants.filter((participant) => {
                        console.log(participant)
                        const onlyNumbersRegex = '/D/g';
                        return (
                            participant.documentNumber
                                .toLocaleLowerCase()
                                .replace(onlyNumbersRegex, '') ===
                            formatedValue.replace(onlyNumbersRegex, '')
                        );
                    })[0];
                    break;
                case 'NAME_AND_DOCUMENT_NUMBER':
                    participantFound = participants.filter(
                        (participant) =>
                            participant.documentNumber.toLocaleLowerCase().trim() === formatedValue ||
                            participant.name === formatedValue,
                    )[0];
                    break;
                case 'PHONE_NUMBER':
                    participantFound = participants.filter(
                        (participant) =>
                            participant.cellPhoneNumber.toLocaleLowerCase().trim() === formatedValue,
                    )[0];
                    break;
                default:
                    return -1;
            }
        }

        return participantFound;
    };

    useEffect(() => {
        setStartTimestamp(new Date())
        getEventInfo();
        loadEventImage();
        setFocus('document');
    }, []);

    const schema = yup
        .object({
            document: yup.string().optional(),
        })
        .required();

    const { register, handleSubmit, setFocus, setValue, watch } = useForm<CheckInFormValues>({
        resolver: yupResolver(schema),
    });

    const hasNameAndSndNm = (name: string) => {
        if (!name.includes(' ')) {
            return false;
        }

        if (!name.split(' ')[1]) {
            return false;
        }

        return true;
    };

    const handleSubmitForm: SubmitHandler<CheckInFormValues> = async (data) => {
        try {
            setIsLoading(true);

            if (!data.document) {
                return;
            }


            if(eventInfos?.participantSearchBy === 'PHONE_NUMBER') {
                data.document = data.document?.replaceAll('(', '')?.replaceAll(')', '')?.replaceAll('-', '')?.replaceAll(' ', '')
            }

            if (data.document === '00000000000') {
                return setParticipantFound({
                    name: 'TESTE', 
                    lastName: 'TESTE',
                    documentNumber: '00000000000',
                    companyName: 'Empresa',
                    companyPosition: 'Teste',
                    id: 0,
                    eventId: 0,
                    state: 'SP',
                    city: 'São Paulo',
                    cellPhoneNumber: '+55 11 999999999',
                    commercialPhoneNumber: '',
                    email: 'teste@teste.com',
                    hasDivergence: false,
                    externalCodeAux: 0,
                    isImportedFromExcel: false,
                    registrationOrigin: 'ADMIN',
                    checkIn: false,
                    checkinDate: '',
                    active: true,
                    categoryId: 1,
                    cekWithdraw: false,
                    cekWithdrawDate: '',
                    linkOrigin: '',
                    additionalInformation: '',
                    additionalCategory: null,
                    checkInDuration: 0,
                    checkInQrCode: null
                })
            }

            console.log(data.document)

            const participants = await ApiService.getAllParticipantsByEvent(
                eventInfos?.id ?? 0,
            ).catch(() => {
                throw new Error('Erro ao consumir a API');
            });

            if (eventInfos?.participantSearchBy === 'NAME' && !hasNameAndSndNm(data.document)) {
                throw new Error('É necessário o nome e o sobrenome');
            }
            

            const participantFound = userFilterDicionary(
                participants,
                eventInfos?.participantSearchBy ?? 'DOCUMENT_NUMBER',
                data.document,
            );
            if (participantFound === -1) {
                throw new Error('Não conseguimos te encontrar com o documento ' + data.document);
            }

            if (!participantFound) {
                setInput('')
                throw new Error(
                    'Desculpe, não localizamos seu cadastro, favor tentar novamente ou procurar a recepção!',
                );
            }
            if (userInfo?.additionalCategory) {
                if(participantFound?.additionalCategory !== userInfo?.additionalCategory) {
                    throw new Error(
                        `Por favor, procure um totem para a categoria ${participantFound?.additionalCategory}`
                    )
                }
            }
            if (!participantFound.active) {
                if (participantFound.linkOrigin) {
                    try{
                        const response = await RsvpService.getPaymentLinkByParticipantId(participantFound.id)
                        if (response) {
                            setPaymentQRCode(response.url)
                            setShowModal(true)
                            setOrderId(response.order_id)
                        }
                    } catch(error: any) {
                        console.log(error)
                    }
                }
                else {
                    throw new Error(
                        'Por favor procure a recepção!'
                    )
                }
            }
            setParticipantFound(participantFound);
        } catch (e: any) {
            setShowModal(true);
            setModalMessage(e.message);
            setTimeout(() => {
                setShowModal(false);
            }, 2000);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setEnglish(language === 'en')
    },[])

    useEffect(() => {
        if(location?.search && eventInfos) {
            const params = new URLSearchParams(location.search)
            const data = params.get('document')
            setInput(data)
            handleSubmitForm({'document': data})
        }
    },[location, eventInfos])

    useEffect(() => {
        if(!paymentQRCode) {
            if(participantFound) {
                eventInfos.checkinType === "CONFIRMATION_REQUIRED"? setShowIdentityModal(true) : onConfirmCheckout();
            }
        }
        else {
            const interval = setInterval(async () => {
                const response = await RsvpService.checkPaymentStatus(orderId)
                if (response.status === 'PAID') {
                    setPaymentQRCode(undefined)
                }
            },2000)

            return (() => clearInterval(interval))
        }

    },[participantFound, paymentQRCode])

    useEffect(() => {
        setValue('document',input)
    },[input])

    useEffect(() => {
        const telMask = (value:string) => {
            return value
            .replace(/\D/g, '') // remove any non-digit character
            .replace(/(\d{2})(\d)/, '($1) $2') // capture the first group of 2 digits and the second group of 1 digit, then add parentheses and a space between them
            .replace(/(\d{4,5})(\d)/, '$1-$2') // capture the first group of 4 or 5 digits and the second group of 1 digit, then add a hyphen between them
            .replace(/(-\d{4})\d+?$/, '$1'); // capture 4 digits followed by a hyphen and don't allow any more digits
        }
        if (eventInfos?.participantSearchBy === 'PHONE_NUMBER' && watch('document')) {
            setValue('document', telMask(watch('document')))
        }
    },[watch('document')])

    return (
        <CheckInContainer backgroundUrl={location.state.backgroundImage ?? backgroundImage}>
            <ModalLoading isActive={isLoading} />
            <ModalConfirmIdentity
                hideModal={() => setShowIdentityModal(false)}
                isActive={showIdentityModal}
                onButtonClick={() => {
                    setShowIdentityModal(false);
                    onConfirmCheckout();
                }}
                participant={participantFound}
            />
            <BackArrow />
            <SearchByContainer>
                {showModal?
                    paymentQRCode?
                        <PaymentContainer>
                            <p>{english? 'Scan the QR code and complete payment on your phone' : 'Escaneie o QR code abaixo e realize o pagamento'}</p>
                            <QRCodeContainer>
                                <QRCode
                                    size={200}
                                    value={paymentQRCode}
                                    viewBox={`0 0 200 200`}
                                    level='M'
                                />
                            </QRCodeContainer>
                        </PaymentContainer>
                    :
                        success? 
                        <PaymentContainer>
                            <WelcomeMessage>
                                {`Bem-vindo, ${participantFound?.name}!`}
                            </WelcomeMessage>
                            {eventInfos?.welcomeMessage &&
                                <WelcomeMessage>
                                    {eventInfos?.welcomeMessage}
                                </WelcomeMessage>
                            }
                        </PaymentContainer>
                        :
                            <ErrorMessage>
                                {modalMessage}
                            </ErrorMessage>
                    : <>
                <CheckInTitle>
                    {' '}
                    {eventInfos?.removeTitleLabel && eventInfos?.description}{' '}
                </CheckInTitle>
                <CheckInForm
                    onSubmit={handleSubmit(handleSubmitForm)}
                    className='align-self-center d-flex'
                >
                    <Form.Group as={Row} className='mb-3 align-self-center'>
                        <FormLabel column sm={2}>
                            {(eventInfos?.participantSearchBy === 'DOCUMENT_NUMBER' ||  eventInfos?.participantSearchBy === 'NAME_AND_DOCUMENT_NUMBER') && eventInfos.details !== '' ? eventInfos.participantSearchBy === 'NAME_AND_DOCUMENT_NUMBER'? `Nome ou ${eventInfos?.details}` : eventInfos?.details : english? IEnglishParticipantSearchBy[eventInfos?.participantSearchBy ?? 'NAME'] : IParticipantSearchBy[eventInfos?.participantSearchBy ?? 'NAME']}
                        </FormLabel>
                        <Row sm={10}>
                            <Form.Control
                                className='input-lg'
                                {...register('document')}
                                type='text'
                                value={watch('document')}
                            />
                        </Row>
                    </Form.Group>
                    <CheckoutButton type='submit' backgroundColor={eventInfos?.buttonColor}> {english? 'Check-in' : 'Realizar Check-in'} </CheckoutButton>
                </CheckInForm>
                </>}
            </SearchByContainer>
            {eventInfos?.useVirtualKeyboard && !showModal?
                <KeyboardContainer>
                    <TKeyboard setInput={(e:string) => setInput(e)}/>
                </KeyboardContainer>
                :
                <div></div>
            }
        </CheckInContainer>
    );
}
