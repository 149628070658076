import Keyboard, { KeyboardReactInterface } from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import "./index.css";

import React, { useRef } from 'react'

interface Props {
    setInput: Function
}

export default function TKeyboard(props: Props) {
    const keyboardRef = useRef<KeyboardReactInterface | null>(null)
    const layout = {
        numeric: {
            default: ["1 2 3", "4 5 6", "7 8 9", " 0 {bksp}",],
            shift: ["! / #", "$ % ^", "& * (", "{shift} ) +", "{bksp}",]
        },
        brazilian: {
            default: [
                "` 1 2 3 4 5 6 7 8 9 0   {bksp}",
                " q w e r t y u i o p ´  ",
                "{lock} a s d f g h j k l ç ~  ",
                " z x c v b n m , . ",
                ".com @ {space}",
            ],
            shift: [
                "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
                " Q W E R T Y U I O P ` { |",
                '{lock} A S D F G H J K L Ç ^ } ',
                " Z X C V B N M < > ? ",
                ".com @ {space}",
            ],
        }
    }

  return (
    <Keyboard 
        keyboardRef={(r) => (keyboardRef.current = r)}
        layout = {layout.numeric}
        onChange={(e) => {props.setInput(e)}} 
        display={{'{bksp}': 'APAGAR','{space}': ' ', '{enter}': 'Enter', '{shift}': 'Shift', '{lock}': 'Fixa'}}
        mergeDisplay={true}
        layoutName={'default'}
        buttonTheme={[
            {
                class: "bold",
                buttons: "1 2 3 4 5 6 7 8 9 0 {bksp} "
            }
        ]}
    />
  )
}
