import styled from 'styled-components';
import Nav from 'react-bootstrap/Nav'

export const EventBackground = styled.div`
    width: 100%;
    height: 100vh;
    padding-bottom: 30px;
    background-color: ${(props) => props.theme.colors['gray-400']};
`

export const RowContainer = styled.div`
    display: grid;
    grid-template-columns: 20% calc(80% - 20px);
    gap: 20px;

    @media (width < 1000px) {
        display: flex;
        flex-direction: column;
    }
`

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    background-color: ${({ theme }) => theme.colors.white};
    overflow-y: scroll;
    max-height: calc(80vh - 60px);
`

export const EventAddForm = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 500;

    font-size: ${({ theme }) => theme.fontSize.form};
    margin: 20px;

    form {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 100%;
    }

    form > div {
        max-width: 700px;
        width: 80%;
    }

    .form-control:focus {
        border: 1px solid ${({ theme }) => theme.colors.steelblue} !important;
        box-shadow: none;
    }

    .form-control {
        border-radius: 4px;

        color: ${({ theme }) => theme.colors['gray-700']};
    }

    .form-label {
        font-weight: 700;
        font-color: ${({ theme }) => theme.colors['gray-700']};
    }

    .form-check {
        float: none;

        > input {
            width: 17px;
            height: 17px;
            box-shadow: none;

            :checked {
                background-color: ${({ theme }) => theme.colors.steelblue};
            }
        }
    }

    .checkboxSection {
        > * {
            margin-bottom: 10px;
            font-weight: 200;
        }
    }
`;

export const Menu = styled.div`
    display: flex;
    flex-direction: column;
    background: #fff;
    margin-top: 30px;
    margin-left: 30px;
    margin-bottom: 30px;
    padding: 2%;
    height: calc(80vh - 64px);

    @media(width < 1000px) {
        margin-left: unset;
        width: 100%;
        height: unset;
        margin-bottom: 0px;
    }
`

export const MenuItem = styled.div`
    display: flex;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    padding: 2%;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    &.selected {
        background-color: #d9d9d9;
    }
`


export const SubmitButton = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    > button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        width: 200px;
        height: 50px;
    }
`;

export const DeleteButton = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;

    > button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: ${({ theme }) => theme.colors['red-500']};

        width: 200px;
        height: 50px;
    }
`;


export const TicketForm = styled.div`
    display: grid;
    grid-template-column: 100%;
    width: 100%;
    align-items: center;
    margin-bottom: 30px;
`

export const AddButton = styled.div`
    border: none;
    padding: 8px 12px;

    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.steelblue};
    color: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => theme.fontSize.sm};

    width: 100px;
    height: 90%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const AddTicketButton = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 30px;
    gap: 10px;
    height: 30px;
`

export const TicketRow = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
`

export const DashedHR = styled.hr`
    width: 95%;

    border-top: 1px dashed black;
    height: 1px;
    margin: 20px 0;
`;

export const WarnText = styled.p`
    font-weight: bolder;
    font-size: ${({ theme }) => theme.fontSize.sm};
    color: ${({ theme }) => theme.colors['red-500']};
    margin: 0;
    padding-top: 5px;
`;

export const CapitalizedRow = styled.td`
    text-transform: capitalize;
`;

export const ImagePreview = styled.img`
    width: 250px;
    height: 100px;
    margin: 10px 0px 20px 0px;
`;

export const Bolder = styled.div`
    font-weight: bold;
`;

export const Title = styled.div`
    font-weight: bold;
    font-size: ${({ theme }) => theme.fontSize.lg};
    margin-bottom: 4vh;
`;

export const Subtitle = styled.div`
    font-weight: normal;
    font-size: ${({ theme }) => theme.fontSize.base};
    margin-bottom: 4vh;
`;

export const Input = styled.input.attrs({ className: 'form-control' })`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors['gray-400']};
  border-radius: 4px;
  font-size: ${({ theme }) => theme.fontSize.form};
  color: ${({ theme }) => theme.colors['gray-700']};
  padding: 8px 16px;
  outline: none;
  transition: all 0.3s ease;

  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.steelblue};
    box-shadow: none;
  }
`;

export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 2vw;
  margin-bottom: 2vh;
`
export const ItemRow = styled.div`
display: flex;
flex-direction: row;
width: 100%;
justify-content: space-between;
align-items: center;
`

export const LinkText = styled.h6`
  color: ${({ theme }) => theme.colors.steelblue};
  cursor: pointer;
`
export const RedText = styled.h6`
  color: ${({ theme }) => theme.colors['red-500']};
  cursor: pointer;
`

